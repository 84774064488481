import { FC } from 'react';
import { PageProps, graphql } from 'gatsby';

import Layout from '../components/Layout/Layout';
import LogoDark from '../assets/logo-dark.svg';

import styles from '../styles/pages/index.module.scss';

export const indexPageQuery = graphql`
  query IndexPageQuery {
    site {
      siteMetadata {
        name
        tagline
      }
    }
  }
`;

type DataProps = {
  site: {
    buildTime: string;
  };
};

const IndexPage: FC<PageProps<DataProps>> = ({ location }) => {
  return (
    <Layout SEOProps={{ title: 'Language' }}>
      <div className={styles.box}>
        <img src={LogoDark} height={48} alt="Smartpay | Docs" />
        <nav>
          <a href="/en">English</a>
          <a href="/ja">日本語</a>
        </nav>
      </div>
    </Layout>
  );
};

export default IndexPage;
